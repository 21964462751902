import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState('');

  const filters = [
    {
      label: 'All',
      value: '',
    },
    {
      label: 'Large',
      value: 'lg',
    },
    {
      label: 'Small',
      value: 'sm',
    },
  ];

  const getProducts = async (filter) => {
    try {
      const { data } = await axios.get('./api/data.json');
      !filter
        ? setProducts(data)
        : setProducts(data.filter((item) => item.size === filter));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts(filter);
  }, [filter]);

  return (
    <section className="mt-12">
      <div className="container m-auto">
        <div className="my-24">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold uppercase tracking-widest text-orange-600 mb-2">
            Hancrafted Bags
          </h1>
          <p className="text-lg text-orange-900">
            Handmade Upholstered Wayúu Mochila, created utilizing ancestral
            techniques representing the history, culture and wisdom of the Wayúu
            peoples. Fully Lined.
          </p>
        </div>
        <div className="mb-6">
          {filters.map((item) => (
            <button
              key={item.label.toLowerCase()}
              className={`border-2 border-orange-800 py-2 px-6 rounded-full font-bold mr-2 ${
                item.value === filter
                  ? 'bg-orange-800 text-white'
                  : 'text-orange-800'
              }`}
              onClick={() => setFilter(item.value)}
            >
              {item.label}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-6 gap-4">
          {!products ? (
            <>No products</>
          ) : (
            products.map((item) => (
              <div
                className="bg-white flex flex-col col-span-6 sm:col-span-3 lg:col-span-2"
                key={item.id}
              >
                <img src={item.image} width="600" height="600" alt="product" />
                <div className="py-4 px-6">
                  <h3 className="font-bold text-lg text-amber-900">
                    {item.name}
                  </h3>
                  <div className="text-amber-800">${item.price}</div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductsPage;
