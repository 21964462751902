import React from 'react';
import AppHeader from '../components/AppHeader';
import { Outlet } from 'react-router-dom';
import AppFooter from '../components/AppFooter';

const DefaultLayout = () => {
  return (
    <>
      <div className="absolute top-0 right-0 -z-0 text-orange-100">
        <svg
          className="w-[960px]"
          viewBox="0 0 770 380"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M0.552612305,0.29586792 L769.552612,0.29586792 L769.552612,380.027832 C769.552612,240.316962 641.385946,170.461527 385.052612,170.461527 C128.719279,170.461527 0.552612305,113.739641 0.552612305,0.29586792 Z"
              id="Path"
              fill="currentColor"
            ></path>
          </g>
        </svg>
      </div>
      <div className="p-6 relative z-10">
        <AppHeader />
        <Outlet />
        <AppFooter />
      </div>
    </>
  );
};

export default DefaultLayout;
