import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home';
import Page404 from '../pages/page404';
import ProductsPage from '../pages/products';
import DefaultLayout from '../layout/DefaultLayout';

const RenderRoutes = () => {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path="/" element={<HomePage />} />
      </Route>

      <Route element={<DefaultLayout />}>
        <Route path="/products" element={<ProductsPage />} />
      </Route>

      <Route path="*" element={<DefaultLayout />}>
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
};

export default RenderRoutes;
