import React from 'react';
import Logo from '../Logo';

const AppHeader = () => {
  return (
    <header>
      <div className="container m-auto">
        <div className="text-orange-600 w-52">
          <Logo />
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
