import React from 'react';

const AppFooter = () => {
  return (
    <footer className="pt-32 pb-12">
      <div className="container m-auto">
        <div className="text-center text-amber-900 text-base opacity-60">
          © {new Date().getFullYear()} Colibri Boutique. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
