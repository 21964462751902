import React from 'react';
import { Link } from 'react-router-dom';
import colibri from '../assets/images/colibri.png';

const Page404 = () => {
  return (
    <div className="container m-auto text-center my-24">
      <h1 className="text-4xl font-bold text-orange-600 mb-6">Oops!</h1>
      <img
        className="m-auto"
        src={colibri}
        alt="colibri"
        width="420"
        height=""
      />
      <p className="text-lg text-orange-900 mb-6">
        It seems like you have wondered away.
      </p>
      <Link
        className="bg-yellow-500 text-white px-6 py-3 inline-block font-bold rounded"
        to="/"
      >
        Go Home
      </Link>
    </div>
  );
};

export default Page404;
